import React, { useState, useEffect, Suspense } from 'react';
import axios from 'axios';
import { BrowserRouter , Routes, Route, Link, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import useFacebookPixel from './components/useFacebookPixel'; // Adjust the path based on where you save the hook
import './App.css';
import { useTransition } from 'react';

// Lazy loading components
const FarmerList = React.lazy(() => import('./components/FarmerList'));
const AddFarmerForm = React.lazy(() => import('./components/AddFarmerForm'));
const AddFarmForm = React.lazy(() => import('./components/AddFarmForm'));
const LoginForm = React.lazy(() => import('./components/LoginForm'));
const RegisterForm = React.lazy(() => import('./components/RegisterForm'));
const FarmerDetail = React.lazy(() => import('./components/FarmerDetail'));
const Home = React.lazy(() => import('./components/Home'));
const Header = React.lazy(() => import('./components/common/Header'));
const Footer = React.lazy(() => import('./components/common/Footer'));
const RetailerPage = React.lazy(() => import('./components/RetailerPage'));
const RMDashboard = React.lazy(() => import('./components/RMDashboard'));
const CallPlanPage = React.lazy(() => import('./components/CallPlanPage'));


function PrivacyPolicy(){
  const navigate = useNavigate();
  const lastUpdated = new Date().toLocaleDateString();

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow">
  <h1 className="text-2xl font-bold text-gray-800 mb-4">Privacy Policy for Krshak</h1>
  <p className="text-gray-600">Last updated: {lastUpdated}</p>
  
  <section className="mt-6">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">What Information We Collect</h2>
    <p className="text-gray-600 mb-4">To provide and improve our service to you, we collect certain information during the registration process and as you use our app:</p>
    <ul className="list-disc pl-8 text-gray-600 mb-4">
      <li><strong>Agri Input Retailer Information:</strong> At registration, we ask for your shop name, mobile number, and pincode. This information helps us tailor our services to your location and business needs.</li>
      <li><strong>Farmer Information:</strong> You have the option to update the app with farmer names, phone numbers, their crops, and crop ages. We utilize this data to facilitate communication with farmers on your behalf, enhancing our service's value to both you and the farmers you serve.</li>
    </ul>
  </section>
  
  <section className="mt-4">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">How We Use Your Information</h2>
    <p className="text-gray-600 mb-4">The information you provide is crucial for us to offer personalized services. Specifically, we use this data to:</p>
    <ul className="list-disc pl-8 text-gray-600">
      <li>Send targeted communications to farmers, based on the information you provide about their crops and needs.</li>
      <li>Enhance our app's functionality and tailor it more closely to your business and the farmers you support.</li>
    </ul>
  </section>
  
  <section className="mt-4">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">Your Data Protection</h2>
    <p className="text-gray-600">We take the protection of your data seriously. Only authorized personnel have access to your information, and we employ robust security measures to prevent unauthorized access or disclosure.</p>
  </section>
  
  <section className="mt-4">
    <h2 className="text-xl font-semibold text-gray-700 mb-3">Contact Us</h2>
    <p className="text-gray-600">If you have any questions or concerns about our Privacy Policy or the data we hold on you, please contact us at team@krshak.com. Your trust in our service is our top priority.</p>
  </section>
</div>

  );
};

function DeleteAccount(){
  const navigate = useNavigate();

  return (
    <div className="max-w-xl mx-auto p-4">
      <h1 className="text-xl font-semibold mb-4">Delete Your Krshak Account</h1>
      <p>We're sad to see you go! If you decide to delete your account, please follow the steps below. Remember, deleting your account is irreversible.</p>
      <ul className="list-disc ml-8 my-4">
        <li><strong>Navigate to Settings:</strong> Open the app and go to the settings page.</li>
        <li><strong>Select 'Delete Account':</strong> Find the option to delete your account. This action may require you to log in again for verification purposes.</li>
        <li><strong>Confirm Deletion:</strong> You'll be asked to confirm your account deletion. Please read the information carefully, as this action cannot be undone.</li>
      </ul>
      <h2 className="text-lg font-semibold mb-2">What Happens When I Delete My Account?</h2>
      <p>You will lose access to your account immediately. All your data will be permanently deleted from our servers within 30 days. You may not be able to recover any data or information from your account in the future.</p>
      <div className="mt-4">
        <p><strong>Need Help?</strong></p>
        <p>If you're experiencing issues or have questions about deleting your account, please contact our support team team@krshak.com for assistance.</p>
      </div>
    </div>
  );
};

function AppContent() {
  const navigate = useNavigate();
  useFacebookPixel();
  const { user, logout, authToken } = useAuth();
  const [farmers, setFarmers] = useState([]);
  const [isPending, startTransition] = useTransition();

  

  const fetchFarmers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/farmers/`, {
        headers: { Authorization: `Token ${authToken}` },
      });
      setFarmers(response.data);
    } catch (error) {
      console.error('There was an error fetching the farmers', error);
    }
  };

  const addFarmer = async (farmer) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/farmers/`, farmer, {
        headers: { Authorization: `Token ${authToken}` },
      });
      fetchFarmers(); // Refresh the list of farmers after adding a new one
      navigate('/'); // Add this line to redirect after adding
    } catch (error) {
      console.error('There was an error adding the farmer', error);
    }
  };

  const refreshFarmers = async () => {
    fetchFarmers();
  };



  return (
    <div className="flex flex-col min-h-screen bg-gray-100 text-gray-900">
    <Header />
    <main className="flex-grow mt-16">
    <Suspense fallback={<div>Loading...</div>}>

    <Routes>
    <Route path="/" element={<MainContent/>} />
    <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
    <Route path="/data-policy" element={<DeleteAccount/>} />
    <Route path="/login" element={<LoginForm />} />
    <Route path="/register" element={<RegisterForm />} />
    <Route path="/add-farmer" element={<AddFarmerForm addFarmer={addFarmer} authToken={authToken} onRefresh={refreshFarmers} />} />
    <Route path="/add-farm" element={<AddFarmForm addFarmer={addFarmer} authToken={authToken} onRefresh={refreshFarmers} />} />
    <Route path="/farmer/:farmerId" element={<FarmerDetail authToken={authToken} onRefresh={refreshFarmers} />} />
    <Route path="/privacy-policy/" element={<PrivacyPolicy/>} />
    <Route path="/data-policy/" element={<DeleteAccount/>} />
    <Route path="/retailer" element={<RetailerPage />} />
    <Route path="/dashboard/" element={<RMDashboard />} />
    <Route path="/callplan/" element={<CallPlanPage />} />
    <Route path="/farmers/" element={<ListPage />} />
    {/* Define other routes here */}
  </Routes>
</Suspense>
    </main>
    <Footer />
  </div>
  
);
}
  
  

  function MainContent() {
    const { user } = useAuth(); // Assuming `useAuth` provides user info
    const navigate = useNavigate();
  
    // Redirect based on user type
    useEffect(() => {
      if (user) {
        
        switch (user.user_type) {
          case 'rm':
            navigate('/dashboard/');
            break;
          case 'retailer':
            navigate('/farmers/');           
            break;
          default:
            navigate('/');
            break;
        }
      }
    }, [user, navigate]);
  
    // Default content if not redirected
    return <Home />;
  }
  

  const ListPage = () => {
    const [isPending, startTransition] = useTransition(); // State for loading indicator
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { authToken, isAuthenticated, login, user, logout } = useAuth();
    const [farmers, setFarmers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [phoneContacts, setPhoneContacts] = useState([]);


    useEffect(() => {
      if (authToken) {
        setLoading(true);
        startTransition(() => {
                fetchFarmers().finally(() => setLoading(false));
            });        
      }
    }, [authToken]);

    const fetchFarmers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/farmers/`, {
          headers: { Authorization: `Token ${authToken}` },
        });
        setFarmers(response.data);
      } catch (error) {
        console.error('There was an error fetching the farmers', error);
      }
    };
  
  
    const addFarmer = async (farmer) => {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/farmers/`, farmer, {
          headers: { Authorization: `Token ${authToken}` },
        });
        fetchFarmers(); // Refresh the list of farmers after adding a new one
        navigate('/'); // Add this line to redirect after adding
      } catch (error) {
        console.error('There was an error adding the farmer', error);
      }
    };
    
    
    
  
    const filteredFarmers = farmers.filter(farmer => 
      typeof farmer.phone === 'string' && farmer.phone.includes(searchTerm)
    );
  
    const handleSearchChange = async (e) => {
      const value = e.target.value;
      startTransition(() => {
        setSearchTerm(value);
        if (!value) {
            setPhoneContacts([]);
        } else {
            const filtered = filteredFarmers(farmers, value);
            if (!filtered.length) {
                fetchContacts(value);
            } else {
                setPhoneContacts(filtered);
            }
        }
    });
  
      const filteredFarmers = farmers.filter(farmer => farmer.phone.includes(value));
      if (filteredFarmers.length === 0) {
        try {
          const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: true });
          const contactsWithPhone = contacts.map(contact => ({
            name: contact.name[0],
            phone: contact.tel[0]
          })).filter(contact => contact.phone.includes(value));
  
          setPhoneContacts(contactsWithPhone);
        } catch (err) {
          console.error('Error accessing contacts:', err);
        }
      } else {
        setPhoneContacts([]);
      }
    };
  
    const fetchContacts = async (value) => {
      try {
          const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: true });
          startTransition(() => {
              setPhoneContacts(contacts.filter(c => c.tel[0].includes(value)));
          });
      } catch (error) {
          console.error('Error accessing contacts:', error);
      }
  };
  
    const addContactAsFarmer = (contact) => {
      navigate('/add-farmer', { state: { name: contact.name, phone: contact.phone } });
    };

    return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-lg p-6">
        <input
          type="text"
          placeholder="Search phone नंबर खोजे..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full p-4 mb-4 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <button
          onClick={() => navigate('/add-farmer')}
          className="w-full mb-4 py-2 px-4 bg-green-500 text-white text-sm font-medium rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50"
        >
          <div>
          {phoneContacts.map(contact => (
            <div key={contact.phone} className="flex justify-between items-center p-2 border-b border-gray-300">
              <span>{contact.name} - {contact.phone}</span>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                onClick={() => navigate('/add-farm', { state: { farmerName: contact.name, phone: contact.phone } })}
              >
                Add Farm
              </button>
            </div>
          ))}
        </div>
          Add Farmer - किसान जोड़े
        </button>
        <FarmerList farmers={filteredFarmers} />
        <button
          onClick={logout}
          className="mt-4 w-full py-2 px-4 bg-red-500 text-white text-sm font-medium rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50"
        >
          Logout
        </button>
      </div>
    </div>
  ) }

  
  

  

function App() {

  return (
    <AuthProvider>
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
